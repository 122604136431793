<template>
  <div
    :class="`card card-body my-3 cursor-pointer flight-cell ${
      isSelected ? 'border border-danger' : ''
    }`"
    @click="selectFlight"
  >
    <div class="row text-center">
      <div class="col-4 font-weight-bold h4">
        {{ flight.DepartureTime }}
      </div>
      <div class="col-4 font-weight-bold h4">
        <img
          :src="flightType == 'One Time' ? '/one_way.png' : '/two_way.png'"
          height="30"
        />
      </div>
      <div class="col-4 font-weight-bold h4">{{ flight.ArrivalTime }}</div>
    </div>

    <div class="row text-center">
      <div class="col-4 h6">
        {{ flight.Departure }}
      </div>
      <div class="col-4 h6">
        {{ flightType }}
      </div>
      <div class="col-4 h6">
        {{ flight.Arrival }}
      </div>
    </div>

    <div class="row text-center mt-2">
      <div class="col-4 h5">
        {{ flight.AirlineName }}
      </div>
      <div class="col-4 offset-4 h4 text-primary font-weight-bold">
        Rs. {{ flight.TotalAmount }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    flight: Object,
    flightType: String,
    flightSelected: Function,
    isSelected: Boolean,
  },
  methods: {
    selectFlight() {
      this.$emit("flightSelected", this.flight);
    },
  },
};
</script>

<style scoped>
.flight-cell {
  background: #ffefdb;
  border-radius: 15px;
  border: 0px;
}
</style>
