<template>
  <div class="container">
    <b-modal
      no-close-on-backdrop
      no-close-on-esc
      v-model="showFlightModal"
      hide-footer
      title="Confirm Flight"
    >
      <flight-modal
        :flight="outboundFlight"
        :inbound-flight="inboundFlight"
      ></flight-modal>
    </b-modal>
    <div class="card">
      <transition mode="out-in" name="fade">
        <div v-if="OutboundFlights.length > 0" key="1" class="card-body m-2">
          <h2>Available Flights</h2>
          <div class="mt-5">
            <transition-group mode="out-in" name="fade" class="row">
              <div
                :key="1"
                :class="searchRoute.FlightMode == 1 ? 'col-12' : 'col-md-6 col-12'"
              >
                <h4>Outbound Flights</h4>
                <flight-cell
                  v-for="(flight, index) in OutboundFlights"
                  :key="index"
                  :flight="flight"
                  :flightType="searchRoute.FlightMode == 1 ? 'One Way' : 'Two Way'"
                  @flightSelected="flightSelected"
                  :isSelected="flight == outboundFlight"
                ></flight-cell>
              </div>

              <div :key="2" class="col-md-6 col-12" v-if="InboundFlights.length > 0">
                <h4>Inbound Flights</h4>
                <flight-cell
                  v-for="(flight, index) in InboundFlights"
                  :key="index"
                  :flight="flight"
                  :flightType="searchRoute.FlightMode == 1 ? 'One Way' : 'Two Way'"
                  @flightSelected="inboundFlightSelected"
                  :isSelected="flight == inboundFlight"
                ></flight-cell>
              </div>
            </transition-group>
          </div>
        </div>
        <div class="card-body m-2" key="2" v-else>
          <h2>Search Flight</h2>

          <form @submit.prevent="searchTrip">
            <div class="row mt-4">
              <div class="col-lg-5 col-md-12">
                <div class="mt-4">
                  <b>From *</b>
                  <b-select
                    autofocus="autofocus"
                    :options="places"
                    v-model="searchRoute.SectorFrom"
                    :disabled="loading"
                    required="required"
                  ></b-select>
                </div>
              </div>
              <div class="col-lg-5 col-md-12 offset-lg-2">
                <div class="mt-4">
                  <b>To *</b>
                  <b-select
                    :options="returnPlaces()"
                    v-model="searchRoute.SectorTo"
                    required="required"
                  ></b-select>
                </div>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-12">
                <b-form-radio-group
                  class="w-100"
                  :options="flightTypeOptions"
                  v-model="searchRoute.FlightMode"
                  buttons="buttons"
                  button-variant="outline-secondary"
                ></b-form-radio-group>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-lg-5 col-md-12">
                <div class="mt-4">
                  <b class="d-flex flex-row">Departure Date*</b>
                  <date-picker 
                    v-model ="searchRoute.DepartureDate"
                    :inputAttr="{required: true}"
                    :disabledDate = "pastDate"
                    :format = "'YYYY-MM-DD'"
                    :title-format = "'YYYY-MM-DD'"
                    :time-title-format = "'YYYY-MM-DD'"
                  ></date-picker>
                </div>
              </div>
              <div class="col-lg-5 offset-lg-2 col-md-12">
                <div class="mt-4" v-if="searchRoute.FlightMode == 2">
                  <b class="d-flex flex-row">Return Date*</b>
                   <date-picker 
                    v-model ="searchRoute.ReturnDate"
                    :format="'YYYY-MM-DD'"
                    :inputAttr="{required: true}"
                    :disabledDate = "returnDateValidate"
                    :title-format = "'YYYY-MM-DD'"
                  ></date-picker>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-lg-12 col-12">
                <div class="mt-4 w-100">
                  <b>Nationality *</b>
                  <b-select
                    autofocus="autofocus"
                    :options="nationality"
                    v-model="searchRoute.Nationality"
                    :disabled="loading"
                    required="required"
                  ></b-select>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-5">
                <div class="mt-4 w-100">
                  <b>Adult *</b>
                  <b-select
                    autofocus="autofocus"
                    :options="adult"
                    v-model="searchRoute.Adult"
                    :disabled="loading"
                    required="required"
                  ></b-select>
                </div>
              </div>
              <div class="col-5 offset-2">
                <div class="mt-4 w-100">
                  <b>Child *</b>
                  <b-select
                    autofocus="autofocus"
                    :options="child"
                    v-model="searchRoute.Child"
                    :disabled="loading"
                    required="required"
                  ></b-select>
                </div>
              </div>
            </div>
            <div class="mt-5">
              <div class="show-message mr-5" v-if="message">
                {{ message }}
              </div>
              <button class="btn btn-primary" :disabled="loading">Search Flight</button>
              <div
                class="spinner-border spinner-border-sm mx-2"
                v-if="loading"
                role="status"
              >
                <div class="sr-only">Loading...</div>
              </div>
            </div>
          </form>
        </div>
      </transition>
    </div>
  </div>
</template>

<style>
 .mx-table-date .today
 {
  color:#FF900A
 }
 .mx-calendar-content .cell.active
 {
  color:#fff;
  background-color:#FF900A !important
 }
 .mx-calendar-header button {
  color: #FF900A !important
 }
</style>


<script>

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import axios from "@/axios";
import moment from "moment";
import FlightCell from "./FlightCell";
import FlightModal from "./FlightModal.vue";
import { applyReactInVue } from 'vuereact-combined';
export default {
  components: { 
  DatePicker,
  FlightCell, 
  FlightModal 
  },
  methods: {
    returnPlaces() {
      return this.places.filter((place) => place != this.searchRoute.from).sort();
    },
    pastDate(date) {
      return date < new Date().setDate(new Date().getDate() - 1);
    },
    returnDateValidate(date){
      return date < this.searchRoute.DepartureDate;
    },

    async searchTrip() {
      this.message = "";
      this.loading = true;
      let res = await axios.post(
        "/api/v2/services/domesticFlight/search",
        this.searchRoute
      );
      this.loading = false;
      if (res.data && res.data.data) {
        this.OutboundFlights = res.data.data.data.Outbound;
        this.InboundFlights = res.data.data.data.Inbound;
        if (this.OutboundFlights.length == 0) {
          this.message = "No flights Found. Please try again.";
        }
      }
    },
    flightSelected(flight) {
      this.outboundFlight = flight;
      if (this.inboundFlight != null || this.searchRoute.FlightMode == 1) {
        this.showFlightModal = true;
      } else {
        this.$bvToast.toast("Please select inbound flight.", {
          title: "Nimbuzz Pay",
          variant: "primary",
        });
      }
    },
    inboundFlightSelected(flight) {
      this.inboundFlight = flight;
      if (this.outboundFlight != null) {
        this.showFlightModal = true;
      } else {
        this.$bvToast.toast("Please select outbound flight.", {
          title: "Nimbuzz Pay",
          variant: "primary",
        });
      }
    },
  },
  data() {
    return {
      outboundFlight: null,
      showFlightModal: false,
      inboundFlight: null,
      message: "",
      places: [
        {
          text: "BHADRAPUR",
          value: "BDP",
        },
        {
          text: "BHAIRAHAWA",
          value: "BWA",
        },
        {
          text: "BHARATPUR",
          value: "BHR",
        },
        {
          text: "BIRATNAGAR",
          value: "BIR",
        },
        {
          text: "DHANAGADHI",
          value: "DHI",
        },
        {
          text: "DOLPA",
          value: "DOP",
        },
        {
          text: "JANAKPUR",
          value: "JKR",
        },
        {
          text: "JOMSOM",
          value: "JMO",
        },
        {
          text: "JUMLA",
          value: "JUM",
        },
        {
          text: "KANGELDANDA",
          value: "KGL",
        },
        {
          text: "KATHMANDU",
          value: "KTM",
        },
        {
          text: "LAMIDANDA",
          value: "LDN",
        },
        {
          text: "LUKLA",
          value: "LUA",
        },
        {
          text: "MANANG",
          value: "NGX",
        },
        {
          text: "MEGHAULY",
          value: "MEY",
        },
        {
          text: "MOUNTAIN",
          value: "MTN",
        },
        {
          text: "NEPALGUNJ",
          value: "KEP",
        },
        {
          text: "PHAPLU",
          value: "PPL",
        },
        {
          text: "POKHARA",
          value: "PKR",
        },
        {
          text: "RAMECHAP",
          value: "RHP",
        },
        {
          text: "RUMJATAR",
          value: "RUM",
        },
        {
          text: "SIMARA",
          value: "SIF",
        },
        {
          text: "SIMIKOT",
          value: "IMK",
        },
        {
          text: "SURKHET",
          value: "SKH",
        },
        {
          text: "TALCHA",
          value: "TAL",
        },
        {
          text: "TUMLINGTAR",
          value: "TMI",
        },
      ],
      OutboundFlights: [],
      ReturnFlights: [],
      nationality: [
        { value: "NP", text: "Nepalese" },
        { value: "IN", text: "Indian" },
      ],
      adult: [...Array(11).keys()].filter((a) => a != 0),
      child: [...Array(10).keys()],
      searchRoute: {
        SectorFrom: "KTM",
        SectorTo: "PKR",
        DepartureDate: moment().format("YYYY-MM-DD"),
        ReturnDate: "",
        FlightMode: 1,
        Nationality: "NP",
        Adult: 1,
        Child: 0,
      },
      loading: false,
      availableShifts: ["day", "night", "both"],
      flightTypeOptions: [
        { text: "One Way", value: 1, selected: true },
        { text: "Two Way", value: 2 },
      ],
    };
  },
  mounted() {},
};
</script>
