<template>
  <b-overlay :show="loading">
    <div v-if="paymentModal">
      <pin-confirm
        :previewDetails="previewDetails"
        :pinVerification="verifyPin"
      ></pin-confirm>
    </div>
    <div>
      <transition name="fade" mode="out-in">
        <div class="container" v-if="flight && !flightBooked" key="1">
          <h5>Outbound Flight</h5>
          <div class="row">
            <div class="col-6 font-weight-bold">Fare Breakup</div>
            <div class="col-6">{{ flight.Adult }} Adult, {{ flight.Child }} Child</div>
          </div>
          <div class="row mt-1">
            <div class="col-6 font-weight-bold">Check-in Baggage</div>
            <div class="col-6">
              {{ flight.FreeBaggage }}
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-6 font-weight-bold">
              Base Fare(Adult) - {{ flight.Adult }}
            </div>
            <div class="col-6">Rs. {{ flight.AdultFare * flight.Adult }}</div>
          </div>

          <div class="row mt-1">
            <div class="col-6 font-weight-bold">
              Base Fare(Child) - {{ flight.Child }}
            </div>
            <div class="col-6">Rs. {{ flight.ChildFare * flight.Child }}</div>
          </div>

          <div class="row mt-1">
            <div class="col-6 font-weight-bold">Tax and Surcharges</div>
            <div class="col-6">Rs. {{ flight.Tax * flight.Adult }}</div>
          </div>
          <div class="row mt-1">
            <div class="col-6 font-weight-bold">Total Amount</div>
            <div class="col-6 text-primary h6">Rs. {{ flight.TotalAmount }}</div>
          </div>
          <hr />

          <div v-if="inboundFlight">
            <h5>Return Flight</h5>
            <div class="row">
              <div class="col-6 font-weight-bold">Fare Breakup</div>
              <div class="col-6">
                {{ inboundFlight.Adult }} Adult, {{ inboundFlight.Child }} Child
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-6 font-weight-bold">Check-in Baggage</div>
              <div class="col-6">
                {{ inboundFlight.FreeBaggage }}
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-6 font-weight-bold">
                Base Fare(Adult) - {{ inboundFlight.Adult }}
              </div>
              <div class="col-6">
                Rs. {{ inboundFlight.AdultFare * inboundFlight.Adult }}
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-6 font-weight-bold">
                Base Fare(Child) - {{ inboundFlight.Child }}
              </div>
              <div class="col-6">
                Rs. {{ inboundFlight.ChildFare * inboundFlight.Child }}
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-6 font-weight-bold">Tax and Surcharges</div>
              <div class="col-6">Rs. {{ inboundFlight.Tax * inboundFlight.Adult }}</div>
            </div>
            <div class="row mt-1">
              <div class="col-6 font-weight-bold">Total Amount</div>
              <div class="col-6 text-primary h6">Rs. {{ inboundFlight.TotalAmount }}</div>
            </div>

            <hr />
          </div>
          <div class="row justify-content-end mt-5">
            <button type="submit" class="btn btn-primary" @click="bookFlight">
              Book Flight
            </button>
          </div>
        </div>
        <div class="container" v-else key="2">
          <form @submit.prevent="confirmFlight">
            <div class="row h5 justify-content-center mb-3">Contact Information</div>
            <div class="row">
              <input
                type="text"
                class="form-control"
                placeholder="Full Name*"
                v-model="flightConfirmParam.ContactPerson"
                required
              />
              <input
                type="email"
                class="form-control mt-3"
                placeholder="Email*"
                v-model="flightConfirmParam.ContactEmail"
                required
              />
              <input
                type="phone"
                class="form-control mt-3"
                v-model="flightConfirmParam.ContactNo"
                placeholder="Contact Number*"
                maxlength="10"
                minlength="10"
                pattern = "[0-9]*"
                required
              />
            </div>

            <div class="row h5 justify-content-center mt-4">Adult Information</div>
            <div
              class="row mt-4"
              v-for="(adult, index) in flightConfirmParam.AdultPassengers"
              :key="'Adult-'+index"
            >
              <b-select :options="titleOptions" v-model="adult.Title"></b-select>
              <input
                type="text"
                class="form-control mt-3"
                placeholder="First Name*"
                v-model="adult.FirstName"
                required
              />
              <input
                type="text"
                class="form-control mt-3"
                placeholder="Last Name*"
                v-model="adult.LastName"
                required
              />
            </div>

            <div
              class="row h5 justify-content-center my-3"
              v-if="flightConfirmParam.ChildPassengers.length > 0"
            >
              Child Information
            </div>
            <div
              class="row mt-4"
              v-for="(child, index) in flightConfirmParam.ChildPassengers"
              :key="'Child-'+index"
            >
              <b-select :options="titleOptions" v-model="child.Title"></b-select>
              <input
                type="text"
                class="form-control mt-3"
                placeholder="First Name*"
                v-model="child.FirstName"
                required
              />
              <input
                type="text"
                class="form-control mt-3"
                placeholder="Last Name*"
                v-model="child.LastName"
                required
              />
            </div>
            <div class="row justify-content-end mt-3">
              <button type="submit" class="btn btn-primary">Confirm Flight</button>
            </div>
          </form>
        </div>
      </transition>
    </div>
  </b-overlay>
</template>

<script>
import axios from "@/axios";
import PinConfirm from "@/components/PinConfirm.vue";
import payment from "@/mixins/payment";
import DatePick from "vue-date-pick";
import 'vue-date-pick/dist/vueDatePick.css';
export default {
  mixins: [payment],
  name: "FlightModal",
  components: { PinConfirm },
  computed: {
    previewDetails() {
      return {
        "Total Passengers": `${this.flight.Adult} Adult, ${this.flight.Child} Child`,
         "Total Amount to Pay":
          "Rs. " +
          `${
            this.inboundFlight
              ? this.inboundFlight.TotalAmount + this.flight.TotalAmount
              : this.flight.TotalAmount
          }`,
      };
    },
  },
  props: {
    flight: Object,
    inboundFlight: Object,
  },
  data() {
    return {
      flightBooked: false,
      loading: false,
      flightConfirmParam: {
        ChildPassengers: [],
        AdultPassengers: [],
        ContactPerson: "",
        ContactEmail: "",
        ContactNo: "",
        pin:""
      },
      titleOptions: ["Mr", "Mrs", "Ms"],
    };
  },
  methods: {
    verifyPin(verified) {
      this.flightConfirmParam.pin = verified;
      this.pinVerification(this.confirmFlight, verified);
    },
    async bookFlight() {
      this.loading = true;
      let res = await axios.post(
        "/api/v2/services/domesticFlight/book",
        {
          OutboundFlightId: this.flight.FlightId,
          InboundFlightId: this.inboundFlight ? this.inboundFlight.FlightId : "",
        }
      );
      this.loading = false;
      this.flightBooked = true;
      this.flightConfirmParam = {  
        amount:
          this.flight.TotalAmount +
          (this.inboundFlight ? this.inboundFlight.TotalAmount : 0),
        Child: this.flight.Child,
        Adult: this.flight.Adult,
        OutboundFlightId: this.flight.FlightId,
        InboundFlightId: this.inboundFlight ? this.inboundFlight.FlightId : "",
        ContactPerson: "",
        ContactEmail: "",
        ContactNo: "",
        Currency: "NPR",
        Nationality: this.flight.Nationality,
        AdultPassengers: [...Array(this.flight.Adult).keys()].map((_) => ({
          FirstName: "",
          LastName: "",
          Title: "Mr",
        })),
        ChildPassengers: [...Array(this.flight.Child).keys()].map((_) => ({
          FirstName: "",
          LastName: "",
          Title: "Mr",
        })),
      };
    },
    async confirmFlight() {
      if (!this.pinVerified) {
        this.paymentModal = true;
        return;
      }
      this.loading = true;
      let res = await axios.post(
        "/api/v4/domestic_flight/payment",
        this.flightConfirmParam,
      );
      this.loading = false;
      this.$bvToast.toast(res.data.message, {
        title: "Flight Payment",
        variant: res.data.status == 1 ? "primary" : "danger",
      });

      if (res.data.status === 1) {
        this.$store.dispatch("UPDATE_BALANCE");
        //ticket
        const transaction = res.data.data._id;
        const ref = res.data.data.ref;
      
      await axios
          .get(`api/v4/transactions/export/${transaction}/${ref}`, {
            responseType: "blob",
          })
          .then((response) => {
            const ticketUrl = URL.createObjectURL(response.data);
            window.open(ticketUrl);
          })
          .then(() => {
            this.$router.push("/");
          });
      }
    },
  },
};
</script>
